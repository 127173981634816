/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string,
} from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import loadable from '@loadable/component';

import QueryString from 'qs';
import { getBannerCode } from '../../../../state/ducks/App/App-Selectors';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getActiveABTest } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { getCheckoutFeatureFlag } from '../../../../state/ducks/Checkout/ducks/App/App-Selectors';
import usePartnershipQuery from '../../../helpers/hooks/usePartnershipQuery';
import PartnershipEyebrow from '../AboveBannerEyebrow/PartnershipEyebrow';
import { useInterstitialPersonalization } from '../../../helpers/Personalization/InterstitialPersonalization/useInterstitialPersonalization';

const LoadableGraphqlBannerCodeContainer = loadable(() => import(/* webpackChunkName: "GraphqlBannerCodeContainer" */ '../GraphqlBannerCode/GraphqlBannerCodeContainer'));
const LoadableGraphqlHeader = loadable(() => import(/* webpackChunkName: "GraphqlHeader" */ './GraphqlHeader')); // TODO - SA
const LoadableCheckoutHeader = loadable(() => import(/* webpackChunkName: "CheckoutHeader" */ './CheckoutHeader/CheckoutHeader'));
const LoadableCheckoutHeaderFood = loadable(() => import(/* webpackChunkName: "CheckoutHeaderFood" */ './CheckoutHeaderFood/CheckoutHeader'));

const HeaderBuilder = ({
    location,
    history,
    brand,
    deviceType,
    presentationFamily,
    bannerCode,
    ffWhichHeaderVariation,
    ffWhichCheckoutVariation,
    abTestCheckoutUniversal,
}) => {
    const searchStr = location.search.slice(1);
    const urlParams = QueryString.parse(searchStr);
    // @intention: Retrieve bannercode from URL params
    // @return: {string}
    const getURLBannerCode = () => urlParams?.r || urlParams?.ref || urlParams?.LocNumber;
    // @intention: check if banner code param exists and not pla
    // @return: {component}
    const partnershipData =   usePartnershipQuery();
    useInterstitialPersonalization(brand);
    const brandPartnerData = partnershipData?.data?.brandPartnerData?.content?.entries?.[0] || [];
    const globalPartnerData = partnershipData?.data?.globalPartnerData?.content?.entries?.[0] || [];

    const renderBanner = () => {
        if ((urlParams?.adtype !== 'pla' || !urlParams?.ref?.includes('pla')) && location.pathname.indexOf('/checkout') <= 0 && !brandPartnerData?.ui?.eyebrow_logo) {
            return <LoadableGraphqlBannerCodeContainer brand={brand} bannerCode={bannerCode || getURLBannerCode()} />;
        }
        if (brandPartnerData?.ui?.eyebrow_logo && globalPartnerData?.images?.eyebrow?.url) {
            return <PartnershipEyebrow data={globalPartnerData} logoClick={brandPartnerData?.logo_click_destination} />;
        }
        return null;
    };

    const renderHeader = () => {
        let LoadableCheckoutHeaderComponent = LoadableCheckoutHeader;

        if (
            (
                ffWhichHeaderVariation
                && ffWhichHeaderVariation[ffWhichCheckoutVariation]
                && ffWhichHeaderVariation[ffWhichCheckoutVariation] !== 'enterprise'
                && location.pathname.indexOf('/checkout/cart') >= 0
            )
            || location.pathname.indexOf('/checkout/shipping') >= 0
            || location.pathname.indexOf('/checkout/payment') >= 0
            || location.pathname.indexOf('/checkout/review-order') >= 0
            || location.pathname.indexOf('/checkout/order-confirmation') >= 0
        ) {
            return null;
        }

        // TODO: Improve this logic in universal
        if (presentationFamily === 'food' && ffWhichCheckoutVariation === 'food' && abTestCheckoutUniversal !== 'universal') {
            LoadableCheckoutHeaderComponent = LoadableCheckoutHeaderFood;
            if (deviceType === 'mobile') {
                return (
                    <LoadableGraphqlHeader
                        isConfirmationPage
                        history={history}
                        brand={brand}
                        deviceType={deviceType}
                        presentationFamily={presentationFamily}
                        brandPartnerData={brandPartnerData}
                        globalPartnerData={globalPartnerData}
                    />
                );
            }
        }

        // Checkout header
        if (['/add-ons', '/wrap-up'].includes(location.pathname)) {
            return <LoadableCheckoutHeaderComponent />;
        }

        return (
            <LoadableGraphqlHeader
                history={history}
                brand={brand}
                deviceType={deviceType}
                presentationFamily={presentationFamily}
                ffWhichCheckoutVariation={ffWhichCheckoutVariation}
                brandPartnerData={brandPartnerData}
                globalPartnerData={globalPartnerData}
            />
        );
    };

    // Precheckout header
    return (
        <header id="global_header_container">
            {renderBanner()}
            {renderHeader()}
        </header>
    );
};

HeaderBuilder.propTypes = {
    brand: object,
    location: object.isRequired,
    history: object.isRequired,
    presentationFamily: string.isRequired,
    deviceType: string,
    bannerCode: string,
    ffWhichCheckoutVariation: string,
    ffWhichHeaderVariation: object,
    abTestCheckoutUniversal: string,
};

HeaderBuilder.defaultProps = {
    brand: {},
    deviceType: '',
    bannerCode: null,
    ffWhichCheckoutVariation: 'flower',
    ffWhichHeaderVariation: {},
    abTestCheckoutUniversal: '',
};

const mapStateToProps = (state) => ({
    bannerCode: getBannerCode(state),
    brand: getBrand(state),
    ffWhichCheckoutVariation: getCheckoutFeatureFlag('which-checkout-variation')(state),
    ffWhichHeaderVariation: getCheckoutFeatureFlag('which-header-variation')(state),
    abTestCheckoutUniversal: getActiveABTest('checkout')(state),
});

export default withRouter(
    connect(
        mapStateToProps,
        null,
    )(HeaderBuilder),
);
